html:not(.root-text-sm):not(.root-text-lg):not(.root-text-xl) [data-class="root-text"] {
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);	
}

.root-text-sm {
	font-size: 15px;

	[data-class="root-text-sm"] {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	}
}

.root-text-lg {
	font-size: 17px;

	[data-class="root-text-lg"] {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	}
}

.root-text-xl {
	font-size: 18px;

	[data-class="root-text-xl"] {
		box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
	}
}